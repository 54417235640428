import {
    Button,
    Link,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { useState } from 'react';

const useHelpDropdownStyles = makeStyles({
    button: {
        color: 'white',
        backgroundColor: 'transparent',
        ...shorthands.border('none'),
        ':hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
        ':focus': {
            backgroundColor: 'transparent',
            color: 'white',
        },
    },
    menuItem: {
        ':hover': {
            backgroundColor: 'none',
            color: 'initial',
        },
        ':focus': {
            backgroundColor: 'none',
            color: 'initial',
        },
        ':active': {
            backgroundColor: 'transparent',
            color: 'white',
        },
    },
    link: {
        color: 'inherit',
        display: 'block',
        width: '100%',
    },
    popover: {
        width: '200px',
    },
});

const HelpDropdown = () => {
    const [open, setOpen] = useState(false);
    const classes = useHelpDropdownStyles();

    const toggleDropdown = () => setOpen(!open);

    return (
        <Menu open={open} onOpenChange={toggleDropdown}>
            <MenuTrigger>
                <Button appearance="subtle" aria-label="Help" className={classes.button}>
                    Help Options
                </Button>
            </MenuTrigger>
            <MenuPopover className={classes.popover}>
                <MenuList>
                    <MenuItem className={classes.menuItem}>
                        <Link
                            href="https://powereng0-my.sharepoint.com/:w:/g/personal/shawn_weekly_powereng_com/ER-mkiN3q6BHrUqA_kemfCoBcEyrKZpn3AmKqVFBpfdw2Q?e=y4u0cu"
                            target="_blank"
                            className={classes.link}
                        >
                            How To Use
                        </Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItem}>
                        <Link
                            href="mailto:shawn.weekly@powereng.com;bwalya.musonda@powereng.com;oge.arum@powereng.com"
                            target="_blank"
                            className={classes.link}
                        >
                            Contact Support
                        </Link>
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default HelpDropdown;
