/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-inferrable-types */

import { makeStyles, shorthands } from '@fluentui/react-components';
import React, { useCallback, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setSelectedChatInConversation, setSelectedConversation } from '../../redux/features/conversations/conversationsSlice';


function debounce<T extends any[]>(func: (...args: T) => void, wait: number): (...args: T) => void {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    function debouncedFunction(...args: T) {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => func(...args), wait);
    }

    debouncedFunction.cancel = () => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
    };

    return debouncedFunction;
}


export const useClasses = makeStyles({
    searchBar: {
        width: '80%',
        maxWidth: '800px',
        ...shorthands.margin('20px', 'auto'),
        position: 'relative',
        '& input': {
            width: '100%',
            height: '2rem',
            outlineStyle: 'none',
            ...shorthands.padding('10px'),
            ...shorthands.border('1px', 'solid', '#ddd'),
            ...shorthands.borderRadius('5px'),
            ...shorthands.padding('0', '1rem'),
            color: 'black',
        },
    },
    dropdown: {
        position: 'absolute',
        left: '28%',
        top: '49px',
        width: '80%',
        maxWidth: '675px',
        ...shorthands.borderRadius('5px'),
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        backgroundColor: '#fff',
        color: 'black',
    },

    dropdownChats: {
        color: '#000',
    },
    dropdownItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...shorthands.padding('10px'),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
        '&:last-child': {
            ...shorthands.borderBottom('none'),
        },
    },
    chatInfo: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
    },
    chatUserName: {
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '4px',
    },
    chatContent: {
        fontSize: '0.85rem',
        color: '#666',
        whiteSpace: 'nowrap',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
    },
    chatTimestamp: {
        fontSize: '0.75rem',
        color: '#999',
        marginLeft: '10px',
    },
});

export default function SearchBar() {
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState<string>('');
    const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);
    const classes = useClasses();

    const allMessages: any[] = useMemo(() => {

        const messages = Object.values(conversations).flatMap((conversation: any) => conversation.messages || []);

        console.log('All Messages:', messages);

        return messages;

    }, [conversations]);

   const filteredChats = useMemo(() => {

        if (!inputValue.trim()) {

            return allMessages;

        }

        const lowerCaseTerm = inputValue.toLowerCase();

        return allMessages.filter(chat =>

            chat.content.toLowerCase().includes(lowerCaseTerm)

        );

    }, [inputValue, allMessages]);

   
    const debouncedDisplayDropdown = useCallback(debounce((searchTerm: string) => {
        setDisplayDropdown(searchTerm.trim().length > 0);
    }, 500), []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        setInputValue(searchTerm);
        debouncedDisplayDropdown(searchTerm); 
    };

    const handleChatSelect = (chatId: string, conversationId: string) => {

        if (conversationId !== selectedId) {

            dispatch(setSelectedConversation(conversationId)); 

            setTimeout(() => {

                dispatch(setSelectedChatInConversation(chatId)); 

            }, 0);

        } else {

            dispatch(setSelectedChatInConversation(chatId));

        }

        setDisplayDropdown(false);

   
    };

    const truncateContent = (content: string, maxLength: number = 50) => {
        return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
    };


    return (
        <>
            <div className={classes.searchBar}>
                <input
                    style={{ color: '#000' }}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search for people, messages, and files"
                />
            </div>
            {displayDropdown && (
                <div className={classes.dropdown}>
                    {filteredChats.map((chat: any) => (
                        <div
                            key={chat.id}
                            onClick={() => chat.id && handleChatSelect(chat.id, chat.chatId)}
                            className={classes.dropdownItem}
                        >
                            <div className={classes.chatInfo}>
                                <span className={classes.chatUserName}>{chat.userName}</span>
                                <span className={classes.chatContent}>{truncateContent(chat.content)}</span>
                            </div>
                            <span className={classes.chatTimestamp}>{new Date(chat.timestamp).toLocaleString()}</span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
