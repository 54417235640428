import React, {  ForwardRefRenderFunction } from 'react';


interface FileUploaderProps {
    acceptedExtensions: string[] | undefined;
    onSelectedFile: (file: File) => void;
    ref?: React.Ref<HTMLInputElement> | string;
}

const FileUploaderBase: ForwardRefRenderFunction<HTMLInputElement, FileUploaderProps> = (
    { acceptedExtensions, onSelectedFile }, 
    ref
) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const selectedFiles = target.files;
        if (!selectedFiles || selectedFiles.length !== 1) {
            console.error('There are none or multiple selected files.');
            return;
        }
        const file = selectedFiles[0]; 
        if (file) {
            onSelectedFile(file);
        } else {
            console.error('The selected file contains no file object.');
        }
    };

    return (
        <input
            ref={ref}
            type="file"
            id="fileInput"
            className="file-uploader-class" // Use a placeholder class name
            accept={acceptedExtensions?.join(',')}
            onChange={onChange}
            title="Upload a .pdf, .txt, .jpg, .png or .tiff file"
        />
    );
};

export const FileUploader = React.forwardRef(FileUploaderBase);